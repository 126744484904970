@tailwind base;

@tailwind components;

@tailwind utilities;

:root {
  --primary-color: #ffffff;
  --secondary-color: #000000;
  --text-color: #2d2d2d;
  --dark-black: #c5197d;

  /* Add more variables as needed */
}

.dark-theme {
  --primary-color: #000000;
  --secondary-color: #ffffff;
  --text-color: #2d2d2d;
  --button-color: #666;
  --green-color: #0A8200;
  --darkgreen-color: #006637;
  --red-color: #FC0079;
  --dark-red: #DC2626;
  --black-color: #272727;
  --light-black: #646464;
  --white-color: #ffffff;
  --blue-color: #76C3E6;
  --darkblue-color: #00398F;
  --silver-color: #eee;
  --mediumsilver-color : #848484;
  --darksilver-color: #00000033;
  --brown-color: #4F4F4F;
  --lightbrown-color: #BABABA;
  --yellow-color: #FFE351;
  --footer-color: #301b1b;
  /* Add more variables for the dark theme */
}

.light-theme {
  --primary-color: #ffffff;
  --secondary-color: #ffffff;
  --text-color: #2d2d2d;
  --button-color: #666;
  --green-color: #0A8200;
  --darkgreen-color: #006637;
  --red-color: #FC0079;
  --dark-red: #DC2626;
  --blue-color: #76C3E6;
  --darkblue-color: #00398F;
  --purple-color:  #740183;
  --sky-color: #D9D0FF;
  --black-color: #272727;
  --light-black: #646464;
  --white-color: #ffffff;
  --silver-color: #eee;
  --darksilver-color: #00000033;
  --mediumsilver-color : #848484;
  --brown-color: #311515;
  --lightbrown-color: #BABABA;
  --yellow-color: #FFE351;
  --footer-color: #301b1b;
  /* Add more variables for the light theme */
}
.bg-red {
  background-color: var(--red-color);
}
.text-darkred {
  color: var(--dark-red);
}
.bg-blue {
  background-color: var(--blue-color);
}
.bg-purple {
  background-color: var(--purple-color);
}
.bg-sky {
  background-color: var(--sky-color);
}
.bg-white {
  background-color: var(--white-color);
}
.text-red {
  color: var(--red-color);
}
.text-white {
  color: var(--white-color);
}
.text-black {
  color: var(--black-color);
}
.text-blue {
  color: var(--blue-color);
}
.text-darkblack {
  color: var(--dark-black);
}
.text-lightblack {
  color: var(--light-black);
}
/* .text-darkblue {
  color: var(--darkblue-color);
} */
.bg-silver {
  background-color: var(--silver-color);
}
.text-mediumsilver {
  color: var(--mediumsilver-color);
}
.bg-yellow {
  background-color: var(--yellow-color);
}
.text-yellow {
  color: var(--yellow-color);
}
.text-silver {
  color: var(--silver-color);
}
/* .bg-darksilver {
  background-color: var(--darksilver-color);
} */
.bg-button {
  background-color: var(--button-color);
}
.text-button {
  color: var(--button-color);
}
.text-color {
  color: var(--text-color);
}
.bg-text-color {
  background-color: var(--text-color);
}
.border-black {
  border-color: var(--black-color);
}
.text-brown {
  color: var(--brown-color);
}

/* /* General Css Starts */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}
.font-13 {
  font-size: 13px;
}
.list-circle {
  list-style-type: circle;
  padding-left: 40px;
}

/* custom css starts */

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

button:focus,
a:focus {
  outline: none;
}

div.react-datepicker-wrapper,
div.react-datepicker__input-container {
  display: block;
  width: 100%;
}

.select-none .active {
  color: var(--dark-black);
}

fieldset {
  border: 1px solid gainsboro;
  padding: 16px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.legend1 {
  margin-bottom: 0px;
  margin-left: 20px;
}

.subscribe_inputbox {
  display: block;
  width: 100%;
  padding: 6px 12px;
  background: transparent;
  border-bottom: 1px solid #fff;
  transition: all 0.2s ease-in-out;
}
.inputbox {
  display: block;
  width: 100%;
  padding: 6px 12px;
  /* background: transparent; */
  border-radius: 4px;
  border: 1px solid #D1D5DB;
  transition: all 0.2s ease-in-out;
}
.inputbox:focus {
  outline: 0;
  border-color: #FC0079;
}
.login_subheader {
  color: #525252;
    text-align: center;
}

select.inputbox {
  position: relative;
  height: 36px;
  /* -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; */
}

/* select.inputbox::after {
    position: absolute;
    content: 'arrow_drop_down';
    top: 50%;
    right: 15px;
    font-family: 'Material Icons';
    font-size: 28px;
    color: #ccc;
    text-transform: none;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    pointer-events: none;
  } */

.waftsrchstyle {
  width: 40px;
  text-align: center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.flex.relative > .m-auto.inputbox {
  min-width: 240px;
}

.adminInput {
  font-size: 12px;
}

div#component-error-text {
  color: #f56565;
  margin: 0;
  font-size: 0.75rem;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  text-transform: capitalize;
}

.waft-gradient {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background: repeating-linear-gradient(
    to right,
    #d68215 0%,
    #fff 25%,
    #d68215 50%,
    #fff 75%,
    #d68215 100%
  );
  width: 100%;
  background-size: 200% auto;
  background-position: 0 100%;
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  z-index: 1000;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.active {
  color: var(--dark-black);
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.new-indicator {
  position: relative;
  display: block;
}
.new-indicator:after {
  position: absolute;
  content: "";
  right: 20px;
  top: 0px;
  background: url("./assets/img/new.gif") no-repeat;
  background-size: contain;
  width: 38px;
  height: 25px;
  z-index: 99;
}
.coming-soon-indicator {
  cursor: not-allowed;
  opacity: 0.5;
}

/* dns-button */

.btn-dns {
  min-width: 80px;
  display: block;
  font-weight: 600;
  padding: 9px 12px;
  border: none;
  border-radius: 20px;
  border: 2px solid var(--red-color);
  background: #fff;
  color: var(--red-color);
  position: relative;
  z-index: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.btn-dns:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--red-color);
  z-index: -1;
  opacity: 0;
  border-radius: 20px;
  -webkit-transform: scale3d(0.7, 1, 1);
  transform: scale3d(0.7, 1, 1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.btn-dns:hover {
  color: #fff;
  /* border-color: var(--black-color); */
  background: var(--red-color);
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.btn-dns:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.btn-dns:focus {
  outline: none;
}

.btn-dns.btn-green {
  color:#fff;
  min-width: auto;
  border: 2px solid var(--mediumsilver-color);
  background: var(--mediumsilver-color);
}

.btn-dns.btn-green:before {
  background: var(--red-color);
}

.btn-dns.btn-green:hover {
  border-color: var(--red-color);
  background: var(--red-color);
}

.btn-dns.btn-red {
  min-width: auto;
  border: 2px solid #e53e3e;
  background: #e53e3e;
}

.btn-dns.btn-red:before {
  background: #c53030;
}

.btn-dns.btn-red:hover {
  border-color: #c53030;
}

.btn-dns.btn-delete {
  background: #0A8200;
}

.btn-dns.btn-blue {
  min-width: auto;
  border: 2px solid #025d8c;
  background: #025d8c;
}

.btn-dns.btn-blue:before {
  background: #004c74;
}

.btn-dns.btn-blue:hover {
  border-color: #004c74;
}

/* icoder button */
.btn-icoder {
  min-width: 80px;
  display: block;
  font-weight: 600;
  padding: 4px 6px;
  border: none;
  font-size: 0.8rem;
  /* border-radius: 5px; */
  border: 2px solid var(--button-color);
  background: var(--button-color);
  color: #ffffff;
  position: relative;
  z-index: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: border-color 0.4s, color 0.4s;
  transition: border-color 0.4s, color 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.btn-icoder:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--text-color);
  z-index: -1;
  opacity: 0;
  border-radius: 2px;
  -webkit-transform: scale3d(0.7, 1, 1);
  transform: scale3d(0.7, 1, 1);
  -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.btn-icoder:hover {
  color: #fff;
  border-color: var(--text-color);
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.btn-icoder:hover::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.btn-icoder:focus {
  outline: none;
}

.container_full {
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}
.ps_productwrap {
  padding-top: 20px;
  position: relative;
  padding-left: 30px;
}
.ps_productdetailwrap {
  padding-top: 20px;
  position: relative;
  padding-left: 10px;
}
.product-label {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 10;
}

.product-label span {
  display: inline-block;
  padding: 0.5em 1em;
  background: var(--black-color);
  color: var(--white-color);
  /* font-size: 12px; */
  line-height: 1.2;
  vertical-align: top;
}
/* tab */

.activeTab {
  margin-bottom: -2px;
  border-top-color: var(--dark-black);
  color: #000000;
  border-top-width: 3px;
  font-weight: 600;
}

/* sidemenu */

.list-reset li a.active {
  color: #2196f3;
}

/* blog */

.blog-det:last-child {
  border-bottom: none;
}

/* footer */

.newsletter_wrapper {
  background: #777;
}

/* stepper */

.counter-reset {
  overflow: hidden;
  counter-reset: step;
}

.stepper {
  list-style-type: none;
  width: calc(50% - 50px);
  float: left;
  position: relative;
  letter-spacing: 1px;
}

.stepper:before {
  content: counter(step);
  counter-increment: step;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  color: #fff;
  background: #cccccc;
  text-align: center;
  border-radius: 25px;
  margin: 0;
}

.stepper:after {
  content: "";
  width: 93%;
  height: 2px;
  background: #cccccc;
  position: absolute;
  left: 7%;
  top: 18px;
}

.stepper:last-child {
  width: 100px;
}

.stepper:last-child:after {
  display: none;
}

.stepper.active:before,
.stepper.active:after {
  background: #d68215;
}

.checkout .stepper {
  width: calc(33.3% - 50px);
}

.checkout .stepper:last-child {
  width: 120px;
}

/* block title */

.block-title,
.block-title-cont {
  border-bottom: 1px solid #e5e5e5;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: normal;
  margin-bottom: 16px;
  text-transform: uppercase;
  padding: 4px 16px 8px 0px;
  position: relative;
}

.block-title-cont {
  text-transform: capitalize;
}

.block-title::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 3px;
  width: 25px;
  background-color: var(--black-color);
}

/* homepage */

.topql:before {
  position: absolute;
  top: 50%;
  left: -16px;
  height: 16px;
  margin-top: -8px;
  border-right: 1px solid #e7e7e7;
  content: "";
  opacity: 0.8;
  display: none;
}

.fsdet > .fsdet_title,
.fslistdet_title {
  height: 36px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.fslistdet_title {
  height: auto;
  line-height: normal;
}

.viewCartlist > div .fslistdet > a {
  height: 56px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.cartno {
  top: -10px;
  right: -15px;
  width: 20px;
  height: 20px;
  background: var(--black-color);
  border-radius: 100%;
  color: var(--silver-color);
  line-height: 20px;
  text-align: center;
  font-weight: bold;
}

.cartlist {
  visibility: hidden;
  opacity: 0;
  transform: translateY(50px);
  transition: transform 0.3s ease;
  background: none repeat scroll 0 0 #fff;
  position: absolute;
  right: 0;
  left: auto;
  top: 32px;
  width: 318px;
  z-index: 99999;
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.255);
}

.cartlist:before {
  content: "";
  display: block;
  background: transparent;
  height: 25px;
  position: absolute;
  top: -25px;
  width: 100%;
}

.cartMain:hover .cartlist,
.cartHover .cartlist {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 0.35s ease, transform 0.35s ease;
}

.fscont > div .fslink:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.34);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

.fscont:hover div a:after {
  opacity: 1;
}

.mcart {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 99;
  transform: translateY(-50%);
}

.mcart li {
  margin-right: 7px;
  opacity: 0;
  border-radius: 0.25rem;
  visibility: hidden;
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -o-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

.mcart li:first-child {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.mcart li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.fscont:hover,
.fscont:last-child {
  /* box-shadow: 1px 1px 2px #d5d5d5; */
  border-right: 2px;
}

.fscont:hover .fsdet h3 {
  color: var(--dark-black);
}

.fscont:hover > div ul li {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.fscont > div div .hover-img {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  transition: all linear 0.2s;
}

.fscont:hover > div div .hover-img {
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  padding: 10px;
}

.homeSlider .slick-slide {
  border-right: none;
}

.homeSlider .slider .slick-slider .slick-prev {
  left: 30px;
  width: 60px;
  height: 60px;
}

.homeSlider .slider .slick-slider .slick-next {
  right: 30px;
  width: 60px;
  height: 60px;
}

.homeSlider .slider .slick-slider .slick-prev:before,
.homeSlider .slider .slick-slider .slick-next:before {
  font-size: 60px;
  color: #ffffff;
}

.gyapuBrand > div ul li {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.gyapuBrand > div ul li:hover {
  -webkit-filter: grayscale(10%); /* Safari 6.0 - 9.0 */
  filter: grayscale(10%);
}

.footTop .footTopicon {
  font-size: 40px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}

/* login */
.socialshare_button{
  border-radius: 5px;
}
.socialshare_button:hover {
  background-color: var(--silver-color);;
}
.NepDiv {
  box-shadow: 0px 0px 15px #dfdfdf;
}

/* why gyapu */

.whygyapuCont:after {
  content: "";
  position: absolute;
  width: 90%;
  left: 50%;
  margin: 0 auto;
  height: 0.5px;
  border-bottom: 1px dashed #212529;
  top: 30%;

  transform: translate(-50%, -50%);
  z-index: -1;
}

.whyGyapu_img {
  width: 130px;
}

.whyGyapu_imgChild {
  height: 30px;
  margin: auto;
}

.whygyapuimgDiv,
.checkoutimgDiv {
  position: relative;
  margin: 0 auto;
  background: white;
  height: 80px;
  width: 80px;
  display: flex;
  border-radius: 100%;
  box-shadow: 5px 5px 10px #e6e6e6;
  justify-content: center;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
}

.whygyapuimgDiv {
  box-shadow: 5px 5px 10px rgba(92, 52, 0, 0.43137254901960786);
}

.whyGyapu .whyGyapu_title {
  color: #ffffff;
}

.whygyapuimgDiv:after,
.checkoutimgDiv:after {
  display: block;
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  left: 50%;
  top: 50%;

  content: "";
  border: 1px dashed #f79d18;

  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
}

.whygyapuimgDivB:after {
  border: 1px dashed #4baea0;
}

.whygyapuimgDivC:after {
  border: 1px dashed #025d8c;
}

.whygyapuimgDivD:after {
  border: 1px dashed #87d38b;
}

.whygyapuimgDivE:after {
  border: 1px dashed #bb8d53;
}

.whygyapuimgDivF:after {
  border: 1px dashed #870685;
}

.whyGyapu.commision:hover .whyGyapu_imgChild {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

/* new loading */

.loading_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.text-red-500 .dot-elastic,
.text-red-500 .dot-elastic::before,
.text-red-500 .dot-elastic::after {
  background-color: #f56565;
}

.dot-elastic {
  margin-left: 8px;
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #ffffff;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -8px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #ffffff;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #ffffff;
  color: #ffffff;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.loading_black .dot-elastic,
.loading_black .dot-elastic::before,
.loading_black .dot-elastic::after {
  background-color: #d68215;
  color: #d68215;
}

/* rating */

.star-rating-outside {
  position: relative;
  margin-right: 12px;
  /* display: inline-block; */
}

.star-rating-inside {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.star-rating-outside:before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: "FontAwesome";
  color: #eeeeee;
  font-size: 19px;
}

.star-rating-inside:before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: "FontAwesome";
  color: var(--black-color);
  font-size: 19px;
}

.review_sidebar .star-rating-outside:before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: "FontAwesome";
  color: #eeeeee;
  font-size: 20px;
}

.review_sidebar .star-rating-inside:before {
  content: "\f005 \f005 \f005 \f005 \f005";
  font-family: "FontAwesome";
  color: var(--black-color);
  font-size: 20px;
}

/* footer */

.foottopql > li .topql:before,
.foottopql > .topql:before {
  display: none;
}

.seoFooter .footerMenu > div {
  display: flex;
  margin-bottom: 0;
}

.seoFooter .footerMenu > div > div span {
  font-weight: bold;
  color: #eeeeee;
}

.seoFooter .footerMenu > div > div .foottopql {
  color: #878787;
}
.seoFooter .footerMenu > div > div .foottopql > li {
  width: 100%;
  display: block;
}

.seoFooter .footerMenu > div > div .foottopql > li .topql {
  margin: 0;
}

.seoFooter .footerMenu > div > div .foottopql > li .topql:before,
.seoFooter .footerMenu > div > div .foottopql > .topql:before {
  display: none;
}

.navfixedblank,
.navfixedblank_last {
  display: none;
}

/* responsive */

@media screen and (max-width: 1023px) {
  .whyGyapu {
    width: 16.6%;
  }

  .foottopql > li .topql:before,
  .foottopql > .topql:before {
    display: block;
  }

  .fscont > div div .hover-img {
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -moz-transition: all linear 0s;
    -webkit-transition: all linear 0s;
    transition: all linear 0s;
  }

  .fscont:hover > div div .hover-img {
    -moz-transition: all linear 0s;
    -webkit-transition: all linear 0s;
    transition: all linear 0s;
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    padding: 0px;
  }

  .cartno {
    top: 0px;
    right: -17px;
  }

  .drawer_orderSum .ordersummary_mob div:first-child {
    margin-right: 4px;
  }

  .drawer_orderSum .ordersummary_mob {
    justify-content: start;
  }
}

@media screen and (max-width: 900px) {
  .navfixedblank_last {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .container_full {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ps_productwrap {
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .sortingCatLeft .topql:before {
    display: block;
  }
  .topql:before,
  .mcart,
  .fscont > div .fslink:after,
  .menu-child {
    display: none;
  }

  .fscont:hover > div a .hover-img {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    padding: 0px;
  }

  .foottopql > li .topql:before,
  .foottopql > .topql:before {
    left: -8px;
  }

  .seoFooter .footerMenu > div {
    flex-wrap: wrap;
  }

  .seoFooter .footerMenu > div > div {
    width: 50%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 639px) {
  .whygyapuimgDiv,
  .checkoutimgDiv {
    height: 60px;
    width: 60px;
  }

  .whyGyapu_imgChild {
    height: 25px;
  }

  .whygyapuimgDiv:after,
  .checkoutimgDiv:after {
    height: 50px;
    width: 50px;
  }
  .foottopql > li .topql:before,
  .foottopql > .topql:before {
    left: -4px;
    height: 10px;
    margin-top: -4px;
  }
}

@media screen and (max-width: 599px) {
  /* .homeSlider .slick-slide img,
    .slider_skeleton > img {
      height: 144px;
      object-fit: cover;
    } */
  .gyapuBrand > div ul li {
    display: none;
  }

  .gyapuBrand > div ul li:nth-child(-n + 4) {
    display: inline-block;
  }

  .whyGyapu {
    width: 33.3%;
  }

  .whygyapuCont:after {
    display: none;
  }

  .footerMenu > div {
    display: flex;
  }

  .footerMenu > div div .foottopql {
    display: block;
  }

  .foottopql > li .topql,
  .foottopql > .topql {
    margin-left: 0;
  }

  .foottopql > li .topql:before,
  .foottopql > .topql:before {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .navfixedblank {
    display: block;
  }

  .viewCart_fixed {
    padding-top: 4px;
    padding-bottom: 4px;
    height: auto;
  }

  .viewCart_Tot {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4px;
  }

  .viewCart_Tot div {
    display: flex;
    font-size: 16px;
  }

  .viewCart_Tot div div:first-child,
  .orderummary_mob div:first-child {
    margin-right: 4px;
  }

  .ps_productwrap {
    padding-left: 3px;
  }
}

@media screen and (min-width: 992px) {
  .blog_img img {
    height: 400px !important;
  }
}

.bg {
  /* background: url('./assets/img/big.jpg') no-repeat; */
  background-size: cover;
}
.odometer-block {
  display: flex !important;
  text-transform: uppercase;
}
.odometer-block > span {
  padding: 0 30px;
}
.odometer-block > span > span {
  display: block;
  font-size: 64px;
  font-weight: bold;
}

/* .bg-grad {
    background-image: linear-gradient(-180deg, #38238b 0%, #6f3577 100%);} */

.bg-grad {
  background-image: linear-gradient(-180deg, #38238b 0%, #6e3477 100%);
}

.btn-grad {
  background: #ffffff;
  border: 1px solid #d68215;
  box-shadow: 0 10px 10px 0 rgba(148, 193, 61, 0.23);
  border-radius: 100px;
}

::selection {
  background: #d68215;
  color: #fff;
}

.article-image {
  background: #f7f7f7 url(./assets/img/logo.svg) no-repeat center center;
}
.megamenu li {
  padding: 5px 10px;
  cursor: pointer;
}
.megamenu li:hover {
  color: #d68215;
}
.megamenu {
  outline: none;
}
.ease-in-out {
  transition: all 0.2s ease-in-out;
}
.rotate-90 {
  transform: rotate(-90deg);
}

.btn {
  padding: 0.5rem 1rem;
  color: #ffffff;
  border-radius: 0.25rem;
}

/* profile */

.addressForm .flex > div label {
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: 0.75rem;

  color: #43414d;
  margin-bottom: 0.5rem;
  font-weight: normal;
}

.quill.ql-editor {
  padding: 10px 0;
}
/* coming soon */

.blink {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.tooltip {
  position: relative;
  cursor: help;
  z-index: 10;
  color: #616161;
}
.tooltip .tooltip-content {
  opacity: 0;
  transform: translateX(-50%) scale(0.5);
  position: absolute;
  left: 50%;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: normal;
  padding: 1px 8px;
  transition: all 0.1s ease-in-out;
  width: 200px;
  display: block;
  transform-origin: center bottom;
  visibility: hidden;
}
.tooltip:hover .tooltip-content {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) scale(1);
}

.basicsHover.tooltip {
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
}

.basicsHover.tooltip:hover {
  color: #d68215;
}

.basicsHover.tooltip .tooltip-content {
  width: auto;
}

.cartHover .cartlist {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 0.35s ease, transform 0.35s ease;
}

/* @tailwind utilities; */

.hc_title_wrap h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  /* line-height: 36px; */
  letter-spacing: 1.2px;
  margin: 0;
}
.hc_paragraph_wrap p {
  font-size: 13px;
  letter-spacing: 0.5px;
}
.hc_button span {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 10px auto 0;
  display: block;
  max-width: 100px;
  min-height: 28px;
  padding: 2px 7px;
  margin-bottom: 10px;
  border: 1px solid #2d2d2d;
}

.hc_button span:hover {
  color: #fff;
  background: #000;
}

@media (min-width: 1024px) {
  .container_new {
    padding-left: 32px;
    padding-right: 32px;
  }
  .hc_title_wrap h2 {
    font-size: 28px;
    line-height: 36px;
  }
  .hc_paragraph_wrap p {
    font-size: 16px;
    line-height: 26px;
  }
  .hc_button span {
    font-weight: 600;
    margin: 16px auto 0;
    max-width: 184px;
    min-height: 44px;
    padding: 9px 14px;
    letter-spacing: 2px;
    border: 2px solid #2d2d2d;
  }
}

@media (min-width: 768px) {
  .container_new {
    align-content: space-between;
    box-sizing: border-box;
    /* display: flex;
    flex-wrap: wrap; */
    margin: 0 auto;
    max-width: 1366px;
    padding: 0 24px;
    text-align: left;
    width: 100%;
  }
}
